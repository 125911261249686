import Phaser from 'phaser'
import BootScene from './scenes/BootScene'
import PlayScene from './scenes/PlayScene'
import BathScene from './scenes/BathScene'

import ShakePositionPlugin from 'phaser3-rex-plugins/plugins/shakeposition-plugin.js';

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.WEBGL,
    width: '100%',
    height: '100%',
    parent: containerId,
    physics: {
      default: 'arcade',
      arcade: {
        debug: false
      }
    },
    scene: [BootScene, PlayScene, BathScene],
    plugins: {
      global: [{
        key: 'rexShake',
        plugin: ShakePositionPlugin,
        start: true
      }]
    },
    transparent: true
  })
}

export default launch
export {
  launch
}
