import {
  Scene
} from 'phaser'

import {VARIETIES} from '@/libs/varieties.js'

const TORTOISE_SIZE = {
  width: 512,
  height: 512
}
const PT_SIZE = {
  width: 400,
  height: 300
}


export default class BootScene extends Scene {
  constructor() {
    super({
      key: 'BootScene'
    })
  }

  preload() {
    for (let [variety_id, variety] of Object.entries(VARIETIES)) {
      this.load.spritesheet(`pt_spritesheet_${variety_id}`, variety.spritesheet_img, {
        frameWidth: PT_SIZE.width,
        frameHeight: PT_SIZE.height
      })
    }
    this.load.spritesheet('egg_spritesheet', require('@/assets/imgs/egg_spritesheet.png'), {
      frameWidth: 1000,
      frameHeight: 500
    })
    this.load.image('happiness_plus', require('@/assets/imgs/UI/happiness_plus.png'))
    this.load.image('happiness_minus', require('@/assets/imgs/UI/happiness_minus.png'))
    this.load.image('uncomfortable', require('@/assets/imgs/UI/uncomfortable.png'))

    this.load.image('food', require('@/assets/imgs/goods/food_normal.png'))

    this.load.image('shelter', require('@/assets/imgs/goods/hole.png'))
    this.load.image('play_background', require('@/assets/imgs/scene/grassland.png'))
    this.load.image('bath_background', require('@/assets/imgs/scene/bath_bg.png'))
    this.load.image('bathtub', require('@/assets/imgs/goods/bathtub.png'))
    this.load.image('pupu', require('@/assets/imgs/pupu.png'))
    this.load.image('brush', require('@/assets/imgs/goods/brush.png'))
    this.load.image('floor_mat', require('@/assets/imgs/goods/floor-mat.png'))
    this.load.image('tortoise_highlight', require('@/assets/imgs/tortoise_highlight.png'))
    this.load.image('heal_popup', require('@/assets/imgs/sick_popup.png'))
  }

  create() {
    for (let [variety_id, variety] of Object.entries(VARIETIES)) {
      this.anims.create({
        key: `standing_${variety_id}`,
        frames: [{
          key: `pt_spritesheet_${variety_id}`,
          frame: 0
        }]
      })

      this.anims.create({
        key: `hiding_${variety_id}`,
        frames: [{
          key: `pt_spritesheet_${variety_id}`,
          frame: 5
        }]
      })

      this.anims.create({
        key: `head_only_${variety_id}`,
        frames: [{
          key: `pt_spritesheet_${variety_id}`,
          frame: 10
        }]
      })

      this.anims.create({
        key: `eating_${variety_id}`,
        frames: this.anims.generateFrameNumbers(`pt_spritesheet_${variety_id}`, {
          prefix: 'eat_',
          start: 15,
          end: 19,
          zeroPad: 2
        }),
        // frameRate: 20,
        duration: 1000,
        repeat: 0
      })

      this.anims.create({
        key: `walking_${variety_id}`,
        frames: this.anims.generateFrameNumbers(`pt_spritesheet_${variety_id}`, {
          prefix: 'walk_',
          start: 20,
          end: 23,
          zeroPad: 2
        }),
        frameRate: 5,
        // duration : 100000,
        repeat: -1
      })

      this.anims.create({
        key: `kicking_${variety_id}`,
        frames: this.anims.generateFrameNumbers(`pt_spritesheet_${variety_id}`, {
          prefix: 'kick_',
          start: 25,
          end: 29,
          zeroPad: 2
        }),
        // frameRate: 5,
        duration : 400,
        repeat: 0
      })

      this.anims.create({
        key: `ass_shaking_${variety_id}`,
        frames: this.anims.generateFrameNumbers(`pt_spritesheet_${variety_id}`, {
          prefix: 'ass_shaking_',
          start: 30,
          end: 33,
          zeroPad: 2
        }),
        // frameRate: 20,
        duration: 1000,
        repeat: -1
      })
    }

    /** egg */
    this.anims.create({
      key: 'egg',
      frames: [{
        key: 'egg_spritesheet',
        frame: 0
      }]
    })

    this.anims.create({
      key: 'egg_broken',
      frames: [{
        key: 'egg_spritesheet',
        frame: 1
      }]
    })

    this.scene.start('PlayScene')
  }
}
