import {
  Scene
} from 'phaser'
import GM from '@/components/game_manager'
import place_manager from '../place_manager'

import Food from '@/components/food.js'
import Poop from '@/components/poop.js'


export default class PlayScene extends Scene {
  constructor() {
    super({
      key: 'PlayScene'
    })
    this.foods = []
    this.poops = []
  }

  create() {
    // triggered when game.scene.stop('PlayScene')
    this.events.on('shutdown', () => {
      this.destroy()
    })

    GM.set_current_scene_key('PlayScene')
    this.souls = GM.souls

    this.init_background_objects()

    this.recreate_place_objects()
  }

  recreate_place_objects() {
    // 先把 selected_soul 清除以重新設為該園區有的
    GM.set_selected_soul(null)

    // 清除飼料
    this.remove_all_foods()

    // recreate tortoises and poop
    for (let soul of this.souls) {
      if (soul.body) soul.destroy()
      if (soul.place_idx === place_manager.current_place_idx) {
        soul.create(this)
        if (!GM.selected_soul) GM.set_selected_soul(soul)
      }
    }

    // poop
    this.remove_all_poops()
    for (let idx = 0; idx < place_manager.current_place.poop_amount; idx++) {
      this.poops.push(new Poop(this, place_manager.current_place_idx))
    }
  }

  update() {
    if (this.souls) {
      for (let soul of this.souls) {
        soul.update()
      }
    }
  }

  destroy() {
    /** 有用到event和timer，要在這邊清除 */
    for (let soul of this.souls) {
      soul.destroy()
    }
    this.remove_all_foods()
    if (this.ground_zone) this.ground_zone.removeAllListeners()
    this.events.off('shutdown')
  }

  init_background_objects() {
    const game_width = this.game.canvas.width,
      game_height = this.game.canvas.height

    const footer_height = 65  // 這高度大約切在 footer 按鈕的上緣

    // create 草地圖
    this.ground = this.add.image(0, game_height * 0.4, 'play_background')
      .setOrigin(0, 0)

    // 取得地平線高度，為草地圖上緣高度
    const horizon_y = this.ground.getBounds().top + 14

    let ground_width = this.ground.getBounds().width
    // create shelter (小龜的窩)
    let shelter_scale = 500 / 1320  // 算出讓寬度為 500 的 scale。1320 為原圖寬度
    this.shelter = this.add.image(ground_width / 2 - 100, horizon_y, 'shelter')
      .setScale(shelter_scale)
      .setOrigin(0, 0.5)  // 參考點定在左邊中間，垂直定在中間是為了對齊地平線位置

    let camera = this.cameras.main
    camera.setBounds(0, 0, this.ground.width, this.ground.height)
    // create ground_zone (放飼料範圍、小龜活動範圍)
    this.ground_zone = this.add.zone(0, horizon_y, this.ground.width, game_height - horizon_y - footer_height)
      .setOrigin(0, 0)
      .setInteractive()
      .on('pointerup', this.on_poke_ground, this)
      // 讓畫面視角可以根據pointermove事件拖曳
      .on('pointermove', p => {
        // 滑鼠移動距離大於 5，才視為拖曳草地
        if (p.getDistance() <= 5) return
        if (!p.isDown) return
        camera.scrollX -= (p.x - p.prevPosition.x)
      })
    this.physics.world.enable(this.ground_zone) // 這行其實不需要，但寫了才看得到 debug line

    // 讓初始視角在地圖中間
    camera.setScroll(ground_width / 2 - game_width / 2, 0)

    // create shadow_zone
    let shelter_bounds = this.shelter.getBounds()
    this.shadow_zone = this.add.zone(shelter_bounds.left + 60, horizon_y, shelter_bounds.width / 3 , shelter_bounds.height / 3) // 這裡的數字是用畫面的感覺抓的
      .setOrigin(0, 0)
    this.physics.world.enable(this.shadow_zone) // 這行其實不需要，但寫了才看得到 debug line
  }

  on_poke_ground(pointer) {
    if (!GM.does_tortoise_exist) return
    // 滑鼠移動距離小於5，才執行放置食物在草地上
    if (pointer.getDistance() > 5) return

    gtag('event', 'feed', {
      'category': 'interaction',
    });

    const food = new Food(this, {
      x: pointer.worldX,
      y: pointer.worldY
    })
    this.add_food(food)
  }

  add_food(food) {
    this.foods.push(food)
    this.make_tortoises_search_food()
  }

  remove_food(food) {
    // TODO: 這邊若有多隻龜幾乎同時吃掉同顆飼料，雖然只有這顆飼料會消失，但這些龜都會加飽食度，待修正
    let food_index = this.foods.findIndex(f => f == food)

    // findIndex 若沒找到會回傳 -1，而 splice 若傳負值進去會陣從列中最後一個元素開始往前改動，所以這裡要擋掉負值
    if (food_index >= 0) {
      this.foods.splice(food_index, 1)
    }

    this.make_tortoises_search_food()
  }

  remove_all_foods() {
    for (let food of this.foods){
      food.destroy()
    }
    this.foods = []

    this.make_tortoises_search_food()
  }

  remove_all_poops() {
    for (let poop of this.poops){
      poop.destroy()
    }
    this.poops = []
  }

  make_tortoises_search_food() {
    for (let soul of this.souls) {
      if (soul.body && soul.body.search_food) {
        soul.body.search_food()
      }
    }
  }
}
