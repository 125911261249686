import Phaser from 'phaser'
import { GAME_OBJECT_DEPTH } from '@/settings/depth'
const RandomDataGenerator = new Phaser.Math.RandomDataGenerator()

export default class Food {
  constructor(scene, position) {
    this.scene = scene

    this.physics = scene.physics
    this.position = position
    this.has_been_eliminated = null  // 用於避免被踢後又被吃或被清除飼料時，會被加回飼料清單的問題

    this.initial_sprite()
  }

  initial_sprite() {
    this.sprite = this.physics.add.image(this.position.x, this.position.y, 'food')
      .setScale(0.1)
      .setDepth(GAME_OBJECT_DEPTH.food)  // 避免蓋在烏龜上
      .setInteractive()
  }

  destroy() {
    this.has_been_eliminated = true
    if (this.sprite) {
      this.sprite.destroy()
    }
  }

  be_eaten() {
    this.scene.remove_food(this)
    this.destroy()
  }

  /**
   * 飼料被踢。
   * 行為：往水平方向移動，速度會減慢直到停止。移動時會先從場景的飼料清單中移除，等飼料大概停止後才會被從新加進飼料清單。
   * 目前被踢的移動距離約為 119
   * @param {*} direction : Boolean. true means right
   */
  be_kicked(direction) {
    const velocity_x = direction? 350 : -350
    const drag = 500  // 物件移動的阻力
    const duration = 1000  // 飼料被踢了之後的大概移動時間，是參考上面兩項參數而定。

    this.scene.remove_food(this)
    this.sprite.setVelocityX(velocity_x).setDrag(drag)
    setTimeout(() => {
      let ground_bounds = this.scene.ground.getBounds()
      let half_food_width = 10

      if (!this.has_been_eliminated && (this.sprite.x > ground_bounds.left - half_food_width && this.sprite.x < ground_bounds.right + half_food_width)) {
        this.position = {
          x: this.sprite.x,
          y: this.sprite.y
        }
        this.scene.add_food(this)
      }
      else {
        if (this) this.destroy()
      }
    }, duration)
  }
}
