import {
  Scene
} from 'phaser'
import GM from '@/components/game_manager'
import { GAME_OBJECT_DEPTH } from '@/settings/depth'


export default class BathScene extends Scene {
  constructor() {
    super({
      key: 'BathScene'
    })
  }

  create() {
    // triggered when game.scene.stop('BathScene')
    this.events.on('shutdown', () => {
      this.destroy()
    })

    GM.set_current_scene_key('BathScene')
    this.souls = GM.souls?.filter(soul => soul.place_idx === GM.current_place_idx)

    this.tortoise_in_bathtub = null  // 在澡盆裡的龜

    const game_width = this.game.canvas.width,
      game_height = this.game.canvas.height

    // create background
    const bath_bg_scale = game_width / 900  // 算出讓背景寬為螢幕寬的 scale。900 為背景原圖寬度
    this.add.tileSprite(0, 0, 900, 2000, 'bath_background')  // 背景原圖: 900*2000
      .setScale(bath_bg_scale)
      .setOrigin(0, 0)

    // create bathtub
    const bathtub_scale = game_width * 0.8 / 1000  // 算出讓澡盆寬為螢幕的 80% 的 scale。1000 為澡盆原圖寬度
    this.bathtub = this.physics.add.sprite(game_width / 2, game_height - 20, 'bathtub')
      .setOrigin(0.5, 1) // 為了可以方便讓澡盆置底，把參考點設在中下
      .setScale(bathtub_scale)
      .setDepth(GAME_OBJECT_DEPTH.bathtub)

    // create tortoises walking zone
    const header_height = 125 // 返回鍵 + 狀態列的總高度
    this.walking_zone = this.add.zone(50, header_height + 40, game_width - 100, this.bathtub.getBounds().top - header_height - 80)  // 因小龜參考點在中心，因此這邊範圍要左右內縮 40，上下內縮 50
      .setOrigin(0, 0)
    this.physics.world.enable(this.walking_zone) // 這行其實不需要，但寫了才看得到 debug line

    // create tortoise
    for (let soul of this.souls) {
      soul.create(this, GM.get_random_pos_by_bounds(this.walking_zone.getBounds()))
    }

    // brush
    let last_pointer_x = null
    let last_pointer_direction = null // Boolean, true: right direction
    this.brush = this.physics.add.image(this.bathtub.getTopRight().x - 20, this.bathtub.getTopRight().y + 20, 'brush') // 在澡盆右上角。20 是個大概，為了不要超出螢幕
      .setScale(0.2)
      .setDepth(GAME_OBJECT_DEPTH.brush)
      .setInteractive({ draggable: true })
      .on('drag', (pointer, dragX, dragY) => {
        this.brush.setPosition(dragX, dragY);

        if (!this.tortoise_in_bathtub || !GM.is_overlaping(this.brush, this.tortoise_in_bathtub.sprite)) return

        // 使用雙等號來同時判斷 null 和 undefined
        if (last_pointer_x == null) {
          last_pointer_x = dragX
        }
        else {
          let pointer_direction = dragX > last_pointer_x
          // dragX !== last_pointer_x 是為了避免拖曳速度慢會過度觸發
          if (pointer_direction !== last_pointer_direction && dragX !== last_pointer_x) {
            if (GM.is_overlaping(this.brush, this.tortoise_in_bathtub.sprite)) this.tortoise_in_bathtub.be_brushed()
            last_pointer_direction = pointer_direction
          }
          last_pointer_x = dragX
        }
      })
      .on('dragend', () => {
        if (this.tortoise_in_bathtub) {
          this.tortoise_in_bathtub.sprite_stop()
        }
      })
  }

  update() {
    if (this.souls) {
      for (let soul of this.souls) {
        soul.update()
      }
    }
  }

  destroy() {
    /** 有用到event和timer，要在這邊清除 */
    for (let soul of this.souls) {
      soul.destroy()
    }
    this.events.off('shutdown')
  }

  set_tortoise_in_bathtub(bath_tortoise) {
    this.tortoise_in_bathtub = bath_tortoise
  }
}
